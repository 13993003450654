import { template as template_7555fdad7a6342eb9261b25a0a24e4d9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7555fdad7a6342eb9261b25a0a24e4d9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
