import { template as template_2d69e66d21be4240a690d259dd2c1af6 } from "@ember/template-compiler";
const FKLabel = template_2d69e66d21be4240a690d259dd2c1af6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
